<template>
  <div></div>
</template>
<script>
import {mapActions, mapMutations} from 'vuex';
import {FETCH_AUTH_DATA_REQUEST, LOGIN_SUCCESS} from '@/store/storeActions';
import {plansTypes} from '@/store/userConstants';

export default {
  components: {},
  data() {
    return {};
  },
  async created() {
    const {id_token: idToken} = this.$route.query;
    let routeName = 'Login';
    if (!!idToken) {
      this.LOGIN_SUCCESS({idToken});
      const res = await this.FETCH_AUTH_DATA_REQUEST();
      if (res && res.account) {
        const planType = res.account.planType;
        routeName = planType !== plansTypes.FREE ? 'Account' : 'Select Plan';
      }
    }
    await this.$router.push({name: routeName});
  },
  methods: {
    ...mapActions([
      FETCH_AUTH_DATA_REQUEST,
    ]),
    ...mapMutations([
      LOGIN_SUCCESS,
    ]),
  },
};
</script>
